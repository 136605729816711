<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th class="text-center">Bg Color</th>
                  <th>Title</th>
                  <th class="text-center">Status</th>
                  <th>Type</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(department, index) in facilities.data"
                  :key="index"
                  v-if="facilities.data.length"
                >
                  <td class="align-middle text-center">
                    {{ facilities.from + index }}
                  </td>
                  <td class="text-center">
                    <div
                      class="p-1 d-inline-block"
                      :style="'background: ' + department.bg_color"
                    ></div>
                  </td>
                  <td>
                    <p class="mb-0">
                      <b>
                        {{ department.title }}
                      </b>
                    </p>
                  </td>
                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      :class="
                        department.status == 1
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                    >
                      {{ department.status == 1 ? "Active" : "Inactive" }}
                    </span>
                  </td>
                  <td class="align-middle">
                    {{
                      department.type == 1
                        ? "Default"
                        : department.type == 2
                        ? "Job Placement"
                        : "Freelancing"
                    }}
                  </td>
                  <td class="align-middle text-center" v-if="permission.edit">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="edit(department.id)"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ facilities.from ? facilities.from : 0 }} to
                  {{ facilities.to ? facilities.to : 0 }} of
                  {{ facilities.total ? facilities.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="facilities"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card
          :title="(form.id == '' ? 'Create' : 'Update') + ' Our Facility'"
        >
          <b-card-text
            ><validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <b-form-group label="Select Type" label-for="type">
                  <validation-provider
                    #default="{ errors }"
                    name="type"
                    rules="required"
                  >
                    <v-select
                      id="type"
                      v-model="form.type"
                      placeholder="Select Type"
                      :options="types"
                      label="name"
                      :reduce="(option) => option.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div class="row">
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Title" label-for="title">
                      <validation-provider
                        #default="{ errors }"
                        name="title"
                        rules="required"
                      >
                        <b-form-input
                          id="title"
                          v-model="form.title"
                          :state="errors.length > 0 ? false : null"
                          name="title"
                          placeholder="Enter Your Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12" v-if="form.type == 2">
                    <!-- email -->
                    <b-form-group label="Sub Title" label-for="sub_title">
                      <validation-provider
                        #default="{ errors }"
                        name="sub_title"
                        rules="required"
                      >
                        <b-form-input
                          id="sub_title"
                          v-model="form.sub_title"
                          :state="errors.length > 0 ? false : null"
                          name="sub_title"
                          placeholder="Enter Your Sub Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12" v-if="form.type == 2">
                    <b-form-group
                      label="Select Career Placement Partner"
                      label-for="job_placement_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="job_placement_id"
                        rules="required"
                      >
                        <v-select
                          id="job_placement_id"
                          v-model="form.job_placement_id"
                          placeholder="Select  Career Placement Partner"
                          multiple
                          :options="placement_partners"
                          label="name"
                          :reduce="(option) => option.id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12" v-if="form.type == 3">
                    <b-form-group
                      label="Select Marketplace"
                      label-for="marketplace_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="marketplace_id"
                        rules="required"
                      >
                        <v-select
                          id="marketplace_id"
                          v-model="form.marketplace_id"
                          placeholder="Select  Marketplace"
                          multiple
                          :options="marketplaces"
                          label="name"
                          :reduce="(option) => option.id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="col-md-12">
                    <div class="d-block" style="height: 350px">
                      <b-form-group label="Description" label-for="description">
                        <validation-provider
                          #default="{ errors }"
                          name="description"
                          rules="required"
                        >
                          <quill-editor
                            id="description"
                            style="height: 220px"
                            class="mb-4"
                            v-model="form.description"
                            name="description"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Your Description"
                          />
                          <div style="clear: both"></div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="border rounded p-2 mb-2">
                      <b-img
                        :src="video_thumbnail"
                        height="174"
                        width="310"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />
                      <b-form-group>
                        <b-form-file
                          class="mt-1"
                          id="video_thumbnail"
                          accept=".jpg, .png, .gif"
                          @change="loadImage($event)"
                          placeholder="Select Video Thumbnail"
                          drop-placeholder="Drop file here..."
                        />
                      </b-form-group>
                      <!-- email -->
                      <b-form-group label="Video ID" label-for="video_id">
                        <validation-provider
                          #default="{ errors }"
                          name="video_id"
                        >
                          <b-form-input
                            id="video_id"
                            v-model="form.video_id"
                            :state="errors.length > 0 ? false : null"
                            name="video_id"
                            placeholder="Enter Your Video ID"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group
                      label="Select Background Color"
                      label-for="bg_color"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="bg_color"
                        rules="required"
                      >
                        <b-form-input
                          id="bg_color"
                          type="color"
                          v-model="form.bg_color"
                          :state="errors.length > 0 ? false : null"
                          name="bg_color"
                          placeholder="Enter Your Background Color"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <!-- submit buttons -->
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer></b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import _ from "lodash";
export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      form: {
        id: "",
        title: "",
        sub_title: "",
        video_id: "",
        status: true,
        video_thumbnail: "",
        job_placement_id: [],
        marketplace_id: [],
        description: "",
        bg_color: "",
        type: 1,
      },
      types: [
        { name: "Default", id: 1 },
        { name: "Job Placement", id: 2 },
        { name: "Freelancing", id: 3 },
      ],
      video_thumbnail: this.$store.state.base_url + "images/course/default.png",
      facilities: {},
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.$store.dispatch("getCareerPlacementPartner");
    this.$store.dispatch("getMarketplace");
    this.Permission();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const facilities = await this.callApi(
        "post",
        "/app/our/facility?page=" + this.search.page,
        this.search
      );
      if (facilities.status == 200) {
        this.facilities = facilities.data.facilities;
      }
    },

    loadImage(e) {
      this.form.video_thumbnail = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.video_thumbnail = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Close() {
      this.form.id = "";
      this.form.title = "";
      this.form.sub_title = "";
      this.form.video_id = "";
      this.form.status = true;
      this.form.video_thumbnail = "";
      this.form.job_placement_id = [];
      this.form.marketplace_id = [];
      this.form.description = "";
      this.form.bg_color = "";
      this.form.type = 1;
      this.video_thumbnail =
        this.$store.state.base_url + "images/course/default.png";
    },

    async edit(id) {
      const facility = await this.callApi(
        "post",
        "/app/our/facility/show/" + id
      );
      if (facility.status == 200) {
        this.form.id = facility.data.facility.id;
        this.form.type = facility.data.facility.type;
        this.form.title = facility.data.facility.title;
        this.form.sub_title = facility.data.facility.sub_title;
        this.form.description = facility.data.facility.description;
        this.form.bg_color = facility.data.facility.bg_color;
        this.form.video_id = facility.data.facility.video_id;
        this.video_thumbnail =
          this.$store.state.base_url + facility.data.facility.video_thumbnail;
        this.form.status = facility.data.facility.status == true ? true : false;

        this.form.job_placement_id = [];
        facility.data.facility.job_placements.forEach((placement) => {
          this.form.job_placement_id.push(placement.id);
        });

        this.form.marketplace_id = [];
        facility.data.facility.marketplaces.forEach((marketplace) => {
          this.form.marketplace_id.push(marketplace.id);
        });
      }
    },

    add() {
      const fd = new FormData();
      if (this.form.video_thumbnail) {
        fd.append(
          "video_thumbnail",
          this.form.video_thumbnail,
          this.form.video_thumbnail.name
        );
      }

      fd.append("id", this.form.id);
      fd.append("title", this.form.title);
      fd.append("sub_title", this.form.sub_title);
      fd.append("video_id", this.form.video_id);
      fd.append("description", this.form.description);
      fd.append("type", this.form.type);
      fd.append("status", this.form.status);
      fd.append("bg_color", this.form.bg_color);

      var self = this;
      for (let i = 0; i < this.form.job_placement_id.length; i++) {
        let job_placement_id = self.form.job_placement_id[i];
        fd.append("job_placement_id[" + i + "]", job_placement_id);
      }

      for (let i = 0; i < this.form.marketplace_id.length; i++) {
        let marketplace_id = self.form.marketplace_id[i];
        fd.append("marketplace_id[" + i + "]", marketplace_id);
      }
      axios
        .post("/app/our/facility/add", fd)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.video_id)
              this.e(e.response.data.errors.video_id[0]);
            if (e.response.data.errors.type)
              this.e(e.response.data.errors.type[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "our.facility.access") {
          this.permission.access = true;
        }

        if (permission.slug == "our.facility.create") {
          this.permission.create = true;
        }

        if (permission.slug == "our.facility.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "our.facility.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
  computed: {
    placement_partners() {
      return this.$store.getters.getCareerPlacementPartner;
    },
    marketplaces() {
      return this.$store.getters.getMarketplace;
    },
  },
  components: {
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
